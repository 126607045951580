import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {DemandeRappelState} from "@way/app/demande-rappel/store/demande-rappel.state";

export const etapeDemandeRappelActions = createActionGroup({
  source: '[Global/DemandeRappel]',
  events: {
    'utilisateurAToucheUnChamps': props<{ fieldname: string }>(),
    'utilisateurAModifieUnChamps': props<{ fieldname: keyof DemandeRappelState; fieldvalue: unknown }>(),
    'utilisateurACliquerSurValider': emptyProps(),
    'APIAPrisEnCompteDemandeRappel': emptyProps(),
    'APIARetournerUneErreurLorsDemandeRappel': emptyProps(),
    'utilisateurACliquerSurDemandeDeRappel': emptyProps(),
    'utilisateurACliquerSurBesoinAideMobile': emptyProps()
  }
});
