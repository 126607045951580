import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'way-cgu',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './cgu.component.html',
  styleUrl: './cgu.component.scss'
})
export class CguComponent {
  public goBack(): void {
    history.back()
  }
}
