import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface dataDialog {
  img: string,
  title: string,
  subtitle: string,
  explication: string,
  buttonLabel: string
}

@Component({
  selector: 'way-erreur-popup',
  standalone: true,
  imports: [MatButtonModule, MatCardModule],
  templateUrl: './erreur-popup.component.html',
  styleUrl: './erreur-popup.component.scss'
})
export class ErreurPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<ErreurPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dataDialog,
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

}
