import { NgModule, inject } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import {ActivatedRouteSnapshot, Route, RouterModule, Routes} from '@angular/router';
import { CguComponent } from './shared/document/cgu/cgu.component';
import { MentionsLegalesComponent } from './shared/document/mentions-legales/mentions-legales.component';
import { DemandeRappelComponent } from './demande-rappel/demande-rappel.component';
import { Store } from '@ngrx/store';
import { RgpdComponent } from './shared/document/rgpd/rgpd.component';
import {etapeDemandeRappelActions} from "@way/app/demande-rappel/store/demande-rappel.actions";


export const resetDemandeRappel = (
  activatedRoute: ActivatedRouteSnapshot,
  stateSnapshot: RouterStateSnapshot,
  store = inject(Store),
) : boolean => {
  store.dispatch(etapeDemandeRappelActions.utilisateurACliquerSurDemandeDeRappel());
  return true;
};

export function disableHelp(route:Route): Route {
  return {
    ...route,
    data: {
      __display_help: false
    }
  };
}

export function isHelpDisabled(activatedRoute : ActivatedRouteSnapshot | null): boolean {
  if(activatedRoute == null)
    return false;

  return activatedRoute.data['__display_help'] === false ||activatedRoute.children.some(c=>isHelpDisabled(c));
}

export const routes: Routes = [
  { path:'devis', loadChildren: () => import('./devis/devis.module').then(m => m.DevisModule) },
  { path:'souscription', loadChildren: () => import('./souscription/souscription.module').then(m => m.SouscriptionModule) },
  { path:'paiement', loadChildren: () => import('./paiement/paiement.module').then(m => m.PaiementModule) },
  { path:'cgu', component: CguComponent },
  { path:'mentions-legales', component: MentionsLegalesComponent },
  { path:'rgpd', component: RgpdComponent },
  { path:'demande-rappel', component: DemandeRappelComponent, canActivate: [resetDemandeRappel]},
  { path:'', redirectTo:'devis', pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
