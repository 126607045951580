import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {LetDirective} from '@ngrx/component';
import {Store} from '@ngrx/store';
import {WayState} from '../store/way-state';
import {ModelDirective} from '@core/forms/model.directive';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatInputFormFieldDirective} from '@core/material-ui/mat-input-form-field.directive';
import {
  SaisieTelephoneFormFieldDirective
} from '@core/material-ui/components/saisie-telephone/saisie-telephone-form-field.directive';
import {SaisieTelephoneComponent} from '@core/material-ui/components/saisie-telephone/saisie-telephone.component';
import {
  SelectionPaysFormFieldDirective
} from '@core/material-ui/components/selection-pays/selection-pays-form-field.directive';
import {SelectionPaysComponent} from '@core/material-ui/components/selection-pays/selection-pays.component';
import {filter, map} from 'rxjs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CaptchaComponent} from "@core/captcha/captcha.component";
import {CaptchaFormFieldDirective} from "@core/captcha/captcha-form-field.directive";
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {globalFeature} from "@way/app/store/global.feature";
import {DemandeRappelState} from "./store/demande-rappel.state";
import {demandeRappelFeature} from "./store/demande-rappel.feature";
import {etapeDemandeRappelActions} from "@way/app/demande-rappel/store/demande-rappel.actions";

@Component({
  selector: 'way-demande-rappel',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    MatButtonModule,
    ModelDirective,
    MatFormFieldModule,
    MatInputModule,
    MatInputFormFieldDirective,
    SaisieTelephoneComponent,
    SaisieTelephoneFormFieldDirective,
    SelectionPaysComponent,
    SelectionPaysFormFieldDirective,
    MatTooltipModule,
    CaptchaComponent,
    CaptchaFormFieldDirective,
    RouterModule,
    MatIconModule
  ],
  templateUrl: './demande-rappel.component.html',
  styleUrl: './demande-rappel.component.scss'
})
export class DemandeRappelComponent {

  protected state$ = this.store.select(demandeRappelFeature.selectDonnees);
  protected isEtapeValide$ = this.store.select(demandeRappelFeature.selectIsEtapeValide);
  protected modelStatus$ = this.store.select(demandeRappelFeature.selectModelStatus);
  protected interactionStatus$ = this.store.select(demandeRappelFeature.selectInteractionStatus);
  protected choixIndicatifs$ = this.store.select(globalFeature.selectIndicatifsTelephoniques).pipe(filter(f => f != null), map(f => f!));

  public constructor(private store: Store<WayState>) {

  }

  public nameOfForm(fieldName: keyof DemandeRappelState) {
    return fieldName;
  }

  public onUtilisateurACliqueSurBoutonValider() {
    this.store.dispatch(etapeDemandeRappelActions.utilisateurACliquerSurValider());
  }

  public onUserAToucheFormulaire(fieldName: keyof DemandeRappelState) {
    this.store.dispatch(etapeDemandeRappelActions.utilisateurAToucheUnChamps({fieldname: fieldName}));
  }

  public onUserAModifieValeurFormulaire(fieldName: keyof DemandeRappelState, value: unknown) {
    this.store.dispatch(etapeDemandeRappelActions.utilisateurAModifieUnChamps({
      fieldname: fieldName,
      fieldvalue: value
    }));
  }

  public goBack(): void {
    history.back()
  }
}

