import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {from, map, Observable, switchMap} from "rxjs";

export interface CoreDalenysDefaultConfiguraton {
  cseKeysUrl:string,
  tokenizeUrl:string,
  apiPublicKey:string,
}

export const CORE_DALENYS_DEFAULT_CONFIGURATION = new InjectionToken<CoreDalenysDefaultConfiguraton>('CORE_DALENYS_DEFAULT_CONFIGURATION');



/**
 * Implémentation de https://developer.dalenys.com/ui/developer-doc/integration-modes/cse.html#step-1-implement-cse-javascript-lib dans un service Angular
 */
@Injectable({
  providedIn: 'root'
})
export class DalenysClientSideEncryptionService {

  constructor(private httpClient: HttpClient, @Inject(CORE_DALENYS_DEFAULT_CONFIGURATION) private configuration:CoreDalenysDefaultConfiguraton) { }

  private fetchPublicKey(serviceUrl:string, apiKeyId:string) : Observable<{encryptionPublicKey:string,encryptionKeyId:string}> {

    return this.httpClient.post(serviceUrl, {apiKeyId: apiKeyId}, {
      responseType: "json",
      headers: {"Accept": "application/json"}
    }) as Observable<{ encryptionPublicKey: string, encryptionKeyId: string }>;
  }

  public encryptPlainText(plainText: string, publicKey:string){
    let cryptoSubtle: SubtleCrypto|null = null;
    const btoa = window.btoa;
    const atob = window.atob;
    // Fix Apple prefix if needed
    if (
      window.crypto &&
      !window.crypto.subtle &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window.crypto as any).webkitSubtle
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cryptoSubtle = (window.crypto as any).webkitSubtle;
    } else if (window.crypto && window.crypto.subtle) {
      cryptoSubtle = window.crypto.subtle;
    }

    if (!cryptoSubtle) {
      throw "No crypto api";
    }

    function getMessageEncoding(message:string) {
      const enc = new TextEncoder();
      return enc.encode(message);
    }

    function encryptMessage(publicKey : CryptoKey, message: string) {
      const encoded = getMessageEncoding(message);
      return cryptoSubtle!.encrypt(
        {
          name: "RSA-OAEP",
        },
        publicKey,
        encoded
      );
    }

    function arrayBufferToBase64(buffer: ArrayBuffer) {
      let binary = "";
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return btoa(binary);
    }

    function strToArrayBuffer(str:string) {
      const buf = new ArrayBuffer(str.length);
      const bufView = new Uint8Array(buf);
      for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
      }
      return buf;
    }

    function importRsaKey(pem : string) {
      // base64 decode the string to get the binary data
      const binaryDerString = atob(pem);
      // convert from a binary string to an ArrayBuffer
      const binaryDer = strToArrayBuffer(binaryDerString);
      return cryptoSubtle!.importKey(
        "spki",
        binaryDer,
        {
          name: "RSA-OAEP",
          hash: "SHA-1",
        },
        true,
        ["encrypt"]
      );
    }

    return from(importRsaKey(publicKey)
      .then((binaryPublicKey) => {
        return encryptMessage(binaryPublicKey, plainText);
      })
      .then((encryptedBytes) => {
        return arrayBufferToBase64(encryptedBytes);
      }));
  }

  public preparePayload(cardInfo : unknown){
    const plainText = JSON.stringify(cardInfo);
    return this.fetchPublicKey(this.configuration.cseKeysUrl, this.configuration.apiPublicKey).pipe(
      switchMap(rsaPublicKey => {
        return this.encryptPlainText(
          plainText,
          rsaPublicKey.encryptionPublicKey
        ).pipe(
          map((encryptedMsd) => {
            return {
              ENCRYPTEDDATA: encryptedMsd,
              ENCRYPTIONKEYID: rsaPublicKey.encryptionKeyId,
              APIKEYID: this.configuration.apiPublicKey,
            };
          })
        )
      })
    );
  }

  public postEncryptedData(postPayload:unknown) : Observable<{ HFTOKEN:string, CARDCODE:string, CARDTYPE:string, CARDVALIDITYDATE:string, error?:string }>{
    return this.httpClient.post(this.configuration.tokenizeUrl, postPayload, {
      responseType: "json",
      headers: {"Accept": "application/json"}
    }) as Observable<{ HFTOKEN:string, CARDCODE:string, CARDTYPE:string, CARDVALIDITYDATE:string, error?:string}>;
  }
}
