<!-- eslint-disable  @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable   @angular-eslint/template/interactive-supports-focus -->
<ng-container>

    <div class="way-card">
      <div class="way-card-header">
        <div class="way-card-header-title">
          <a class="lienRetour" (click)="goBack()" 
            style="display: inline; margin-left: 10px;margin-right: 10px;margin-bottom: 20px;vertical-align: middle;">
            <mat-icon>
              arrow_back
            </mat-icon>
          </a>
          <h2 i18n="@@RGPD.Headline">NOTICE INFORMATIVE SUR LA PROTECTION DE VOS DONNEES A CARACTERE PERSONNEL</h2>
        </div>
      </div>
  
      <div class="way-card-content">
  
        <div class="way-card-content-ct">
  
          <div class="way-subcards">
  
            <div class="way-subcards-item" i18n="@@RGPD.EnTete">
                <p>La société <strong>MSH INTERNATIONAL, société du Groupe DIOT - SIACI</strong>, dont le siège social est situé à 39 rue Mstislav Rostropovitch - 75017 Paris, s'engage à assurer la protection, la confidentialité et la sécurité de vos données à caractère personnel et ce dans le respect du Règlement de l'Union Européenne 2016/679 du Parlement Européen et du Conseil du 27 avril 2016 (Règlement Général sur la Protection des Données dit « RGPD ») et de la loi modifiée n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.</p>
                <p>Cette notice vous informe sur la façon dont nous, MSH INTERNATIONAL, nos sous-traitants, traitent vos données à caractère personnel dans le cadre de la souscription et/ou de l'exécution de votre contrat d'assurance.</p>
                <p>Que vous soyez déjà client ou prospect, cette notice est applicable à toutes les personnes physiques, dont nous traitons les données à caractère personnel dans ce cadre.</p>
            </div>

            <div class="way-subcards-item" i18n="@@RGPD.PourquoiTraitonsDonnees">
                <p><strong>POURQUOI TRAITONS-NOUS VOS DONNEES ?</strong></p>

                <p>Nous traitons exclusivement vos données à caractère personnel dans le cadre de finalités déterminées, explicites et légitimes, en stricte conformité avec les principes du RGPD.</p>

                <p><strong>MSH INTERNATIONAL</strong>, en tant que responsable de traitement, réalise des traitements dont les finalités sont les suivantes :</p>

                <ul>
                    <li>Emission du devis</li>
                    <li>Souscription du contrat</li>
                    <li>Exécution du contrat (administrative et financière)</li>
                    <li>Création de compte client </li>
                    <li>Gestion des sinistres</li>
                    <li>Enquête de qualité pour évaluer votre niveau de satisfaction et l'améliorer </li>
                    <li>Prospection commerciale</li>
                    <li>Respect des obligations légales</li>
                    <li>Gestion des réclamations</li>
                    <li>Statistiques et études actuarielles</li>
                </ul>

                <p>La base légale des traitements est l'exécution du contrat d'assurance. </p>
                <p>Les traitements statistiques, d'anonymisation et de prospection commerciale s'appuient sur une base légale liée à l'intérêt légitime de MSH INTERNATIONAL pour l'amélioration de ses services.</p>

                <p>MSH INTERNATIONAL pourra également traiter vos données pour répondre à ses obligations légales ou réglementaires et ce, en tant que responsable de traitement.</p>
                <p>A cet effet, les finalités poursuivies seraient :</p>
                <ul>
                    <li>• Conserver les données requises pour être en mesure de répondre à ses obligations légales</li>
                    <li>• Gérer les demandes de communication de données des autorités habilitées comme la CNIL</li>
                </ul>
            </div>

            <div class="way-subcards-item" i18n="@@RGPD.TempsDonnees">
                <p><strong>COMBIEN DE TEMPS SERONT CONSERVEES VOS DONNEES ?</strong></p>

                <p>Nous ne conserverons vos données à caractère personnel que pendant la durée nécessaire aux fins indiquées dans la présente Notice informative sur la protection de vos données à caractère personnel puis elles seront supprimées ou anonymisées une fois qu'elles ne sont plus nécessaires.</p>
            </div>

            <div class="way-subcards-item" i18n="@@RGPD.@@RGPD.QuellesDonnees">
                <p><strong>QUELLES SONT LES DONNEES TRAITEES ?</strong></p>

                <p>Nous veillons à ce que la collecte de ces données soit pertinente, adéquate, non excessive et strictement nécessaire à nos activités.<p></p>

                <p>Ces données pourront être des :</p>
                <ul>
                    <li>Données d'identification : Nom, prénom, tranche d'âge </li>
                    <li>Données de contact : email, numéro de téléphone, adresse postale, adresse de facturation</li>
                    <li>Données nécessaires à l'établissement du devis (motif du séjour, période de couverture du contrat d'assurance, pays de domicile, pays de destination, titulaire d'une carte d'assurance maladie) </li>
                    <li>Adresse électronique</li>
                    <li>Données techniques (adresse IP)</li>
                </ul>
            </div>

            <div class="way-subcards-item" i18n="@@RGPD.DestinatairesDonnees">
                <p><strong>QUELS SONT LES DESTINATAIRES DE VOS DONNEES ?</strong></p>

                <p>Dans le cadre des différentes actions que nous opérons, vos données peuvent être rendues accessibles aux catégories de personnes suivantes :</p>

                <ul>
                    <li>les personnels chargés de la passation, la gestion et l'exécution des contrats,</li>
                    <li>les médecins-conseils et le personnel habilité à accéder aux données de santé,</li>
                    <li>les délégataires de gestion, les intermédiaires d'assurance, les partenaires,</li>
                    <li>les prestataires et les sous-traitants,</li>
                    <li>les entités du groupe DIOT-SIACI auquel appartient le responsable de traitement dans le cadre de l'exercice de leurs missions,</li>
                    <li>s'il y a lieu les co-assureurs et réassureurs, </li>
                    <li>les personnes intervenant au contrat tels que les avocats, experts, auxiliaires de justice et officiers ministériels, curateurs, tuteurs, enquêteurs.</li>
                </ul>
            </div>

            <div class="way-subcards-item" i18n="@@RGPD.DonneesTraitees">
                <p><strong>OU SONT TRAITEES VOS DONNEES ?</strong></p>

                <p>Vos données personnelles pourront être traitées aussi bien à l'intérieur qu'à l'extérieur de l'Union européenne (UE) toujours sous réserve des restrictions contractuelles relatives à la confidentialité et à la sécurité, conformément à la législation et à la réglementation applicables en matière de protection des données.</p>
                <p>Nous prendrons des mesures afin de garantir que le transfert de vos données personnelles hors UE soit effectué selon le niveau de protection adéquat, au même titre que s'il s'agissait d'un transfert à l'intérieur de l'UE.</p>
            </div>

            <div class="way-subcards-item" i18n="@@RGPD.Droits">
                <p><strong>QUELS SONT VOS DROITS ?</strong></p>

                <p>Conformément à la réglementation applicable, vous disposez de différents droits, à savoir :</p>
                <ul>
                    <li>Droit d'accès : vous pouvez obtenir des informations concernant le traitement de vos données à caractère personnel ainsi qu'une copie de ces données à caractère personnel.</li>
                    <li>Droit de rectification : si vous estimez que vos données à caractère personnel sont inexactes ou incomplètes, vous pouvez exiger que ces données à caractère personnel soient modifiées en conséquence.</li>
                    <li>Droit à l'effacement : vous pouvez exiger l'effacement de vos données à caractère personnel, dans la mesure permise par la loi.</li>
                    <li>Droit à la limitation du traitement : vous pouvez demander la limitation du traitement de vos données à caractère personnel.</li>
                    <li>Droit d'opposition : vous pouvez vous opposer au traitement de vos données à caractère personnel, pour des motifs liés à votre situation particulière. Vous disposez du droit absolu de vous opposer au traitement de vos données à caractère personnel à des fins de marketing direct, y compris le profilage lié au marketing direct.</li>
                    <li>Droit de retirer votre consentement : si vous avez donné votre consentement au traitement de vos données à caractère personnel (ce droit ne s'appliquant qu'aux traitements fondés sur la base légale du consentement), vous avez le droit de retirer ce consentement à tout moment.</li>
                    <li>Droit à la portabilité de vos données : quand ce droit est applicable, vous avez le droit à ce que les données à caractère personnel que vous nous avez fournies vous soient restituées ou, lorsque cela est possible techniquement, de les transférer à un tiers.</li>
                </ul>

                <p>Si vous souhaitez exercer les droits listés ci-dessus, vous pouvez adresser une demande écrite tel que précisé dans la section « COMMENT NOUS CONTACTER ? ».</p>
                <p>Toute demande devra être adressée avec une preuve de votre identité (copie de votre carte d'identité par exemple).</p>
                <p>Conformément à la réglementation applicable, en plus de vos droits susmentionnés, vous êtes également en droit d'introduire une réclamation auprès de la CNIL - https://www.cnil.fr</p>
            </div>

            <div class="way-subcards-item" i18n="@@RGPD.Securite">
                <p><strong>COMMENT VOS DONNEES SONT-ELLES SECURISEES ?</strong></p>

                <p>Nous nous assurons que vos données sont traitées en toute sécurité et confidentialité, y compris lorsque certaines opérations sont réalisées par nos prestataires de services informatiques.</p>

                <p>A cet effet, les mesures techniques et organisationnelles appropriées pour éviter la perte, la mauvaise utilisation, l'altération et la suppression des données personnelles vous concernant sont mises en place. Ces mesures sont adaptées selon le niveau de sensibilité des données traitées et selon le niveau de risque que présente le traitement ou sa mise en œuvre.</p>
            </div>

            <div class="way-subcards-item" i18n="@@RGPD.Contacte">
                <p><strong>COMMENT NOUS CONTACTER ?</strong></p>

                <p>Si vous avez des questions concernant l'utilisation de vos données à caractère personnel dans le cadre de la présente Notice informative sur la protection de vos données à caractère personnel, vous pouvez contacter notre responsable de la protection des données par email adressé au Data Protection Officer à l'adresse suivante : dpo&#64;s2hgroup.com qui fera ses meilleurs efforts pour répondre à celles-ci dans les meilleurs délais.</p>
            </div>
            <div class="way-subcards-item" i18n="@@RGPD.Footer">
                <p>MSH INTERNATIONAL - Groupe DIOT-SIACI - SAS au capital de 2 500 000,00 €</p>
                <p>Siège social : 39, rue Mstislav Rostropovitch - 75017 Paris - France</p>
                <p>352 807 549 RCS Paris</p>
                <p>Société de Courtage d'assurance et de réassurance - Inscrite à l'ORIAS n° 07 002 751 - www.orias.fr</p>
                <p>ACPR - 4 place de Budapest - CS 92459 - 75436 Paris cedex 09 - France</p>
            </div>
        </div>

      </div>
    </div>
  </div>

</ng-container>