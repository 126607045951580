import { CommonModule } from '@angular/common';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BASE_API_URL } from '@core/backend/services/base-api.url';

@Component({
  selector: 'way-mentions-legales',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: './mentions-legales.component.html',
  styleUrl: './mentions-legales.component.scss'
})
export class MentionsLegalesComponent {

  public readonly currentLanguage:string;

  constructor(@Inject(BASE_API_URL) public baseUrl: string, @Inject(LOCALE_ID) locale: string) {
    this.currentLanguage = locale == 'en-gb'? 'en':locale;
  }

  public goBack(): void {
    history.back()
  }
}
